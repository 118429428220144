import { api } from "./index";

//!------------------------------//
//! --- dashboard api routes --- //
//!------------------------------//

// --- user dashboard --- //
export const getDashboard = (exclude, rewardState) =>
	api.get(`/dashboard/get?exclude=${exclude}&rewardState=${rewardState}`);
export const claimReward = (contentID) =>
	api.patch(`/dashboard/redeem/reward/${contentID}?type=reward`);
export const claimLevelRewards = (contentID) =>
	api.patch(`/dashboard/redeem/level/${contentID}?type=level`);

// -- staff dashboard -- //
export const getXpFree = (input) =>
	api.patch(`/staff/test/${input.method}/${input.amount}`);
export const toggleRaidMode = () => api.patch("/staff/raid/switch");
export const getRaidModeStatus = () => api.get("/staff/raid/get");
export const forceEmailFetch = (target) =>
	api.get(`/staff/force/email/get/${target}`);
export const forceVerification = (target) =>
	api.patch("/staff/force/verify", target);
export const forceDeactivation = (target) =>
	api.patch("/staff/force/deactivate", target);
export const deleteStaffSession = () => api.patch("/staff/session/delete");
export const confirmStaffSession = (staffToken) =>
	api.patch("/staff/session/confirm", { staffToken });
export const devGenerateStaffToken = () => api.patch("/staff/session/generate");
export const getGameAssets = (folder) => api.get(`/staff/assets/${folder}`, {
	responseType: 'blob',
});
